import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ButtonDropdownService } from './button-dropdown.service';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(BsDropdownDirective, { static: false }) dropdown: BsDropdownDirective;

  @Input() customClass = '';
  @Input() icon = 'more_horiz';
  @Input() transparent = true;
  @Input() isSetWidth  = true;
  @Input() placement = 'bottom right';
  @Input() dropdownClass = 'dropdown-menu-right';
  @Output() shown = new EventEmitter<void>();
  @Output() hidden = new EventEmitter<void>();

  constructor(private buttonDropdownService: ButtonDropdownService) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.buttonDropdownService.unregister(this.dropdown);
  }

  onToggleDropdown(isOpen: boolean) {
    if (isOpen) {
      this.buttonDropdownService.register(this.dropdown);
    } else {
      this.buttonDropdownService.unregister(this.dropdown);
    }
  }

  onShowDropdown() {
    this.shown.emit();
  }

  onHideDropdown() {
    this.hidden.emit();
  }
}
