import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  TemplateRef
} from '@angular/core';
import { I18nComponent } from '../i18n/i18n.component';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalContainerComponent } from '../modal-container/modal-container.component';

@Component({
  selector: 'x-general-confirm',
  templateUrl: './general-confirm.component.html',
  styleUrls: ['./general-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, I18nComponent, ModalContainerComponent]
})
export class GeneralConfirmComponent {
  title: string = '';
  titleParams: Record<string, string> = {};
  message: string = '';
  params: Record<string, string> = {};
  templateRef?: TemplateRef<any>;
  saveBtnName: string = 'Yes';
  cancelBtnName: string = 'No';
  canAction: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<GeneralConfirmComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: Partial<{
      title: string;
      titleParams: Record<string, string>;
      message: string;
      params: Record<string, string>;
      templateRef: TemplateRef<any>;
      saveBtnName: string;
      cancelBtnName: string;
      canAction: boolean;
    }> = {}
  ) {
    Object.assign(this, data);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
