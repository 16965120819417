import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from 'humanize-duration';
import * as moment from 'moment';
import { AppInjector } from 'src/app/app.module';
import { TimeDurationService } from 'src/app/core/_services/time-duration.service';
import { LeaveRequestUI, DATE_FORMAT, TIME_FORMAT, Shift, LeaveRequest } from 'src/app/shared';

export const VALID_GAP_DURATION = 20;

export interface GapTimeObject {
  value: number;
  units?: Unit[];
  round?: boolean;
  maxDecimalPoints?: number;
}

export class GapTime {
  value: number;
  positiveValue: string;
  displayValue: string;
  units: Unit[];
  round: boolean;
  maxDecimalPoints: number;
  readonly isValid: boolean;
  private timeDurationService: TimeDurationService = AppInjector.get(TimeDurationService);

  constructor(gapTimeObj: GapTimeObject) {
    this.value = gapTimeObj.value;
    this.units = gapTimeObj?.units ?? ['h'];
    this.round = gapTimeObj?.round ?? false;
    this.maxDecimalPoints = gapTimeObj?.maxDecimalPoints ?? 1;
    this.isValid = this.getValid();
    this.positiveValue = this.getPositiveValue();
    this.displayValue = this.getDisplayValue();
  }

  getValid() {
    return this.units.length === 1
      ? this.value >= 0 || +this.getPositiveValue().slice(0, -1) === 0
      : this.value >= 0;
  }

  getPositiveValue() {
    const rawValue = this.timeDurationService.getHumanizeDuration(
      this.value,
      {
        units: this.units,
        round: this.round,
        maxDecimalPoints: this.maxDecimalPoints
      }
    );

    return this.units.length === 1
      ? (() => {
        const value = Number.parseFloat(rawValue.slice(0, -1)).toFixed(1);
        return (+value === 0 ? '0' : value) + this.units[0];
      })()
      : rawValue;
  }

  getDisplayValue(): string {
    return (this.isValid ? '' : '-') + this.positiveValue;
  }
}

@Pipe({ name: 'gapTime'})
export class GapTimePipe implements PipeTransform {
  transform(row: (LeaveRequestUI | LeaveRequest), earliestShift?: Shift): GapTime {
    const requestLists = [...row.requestGroupListResponse];
    requestLists?.sort((a, b) => moment(a.fromDate).diff(b.fromDate));

    const nearestRequest = requestLists[0];
    const shiftTimePoint = nearestRequest?.shiftFromTime
      ? moment.utc(nearestRequest.shiftFromTime).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
      : `${nearestRequest.fromDate} ${earliestShift?.fromTime}`;

    const diffDuration = moment(shiftTimePoint).diff(row.submittedTime);

    if (isNaN(diffDuration)) {
      return null;
    }

    return new GapTime({value: diffDuration, maxDecimalPoints: 2});
  }
}
