import { BoardStatus, BoardReleaseStatus } from '..';

export interface BoardReleaseDetail {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  deleted: boolean;
  status: BoardReleaseStatus;
  countTask: number;
  countStatus: BoardStatus[];
  closeStatusIds: number[];
  showPlanning: boolean;
  numberTaskUnresolved: number;
  storyline: boolean;
}

export interface BoardReleaseRequest {
  showSubtask: boolean;
}
