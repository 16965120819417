import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({ selector: '[autoWidth]' })
export class AutoWidthDirective implements AfterViewInit, OnChanges {
  @Input() currentValue: string | number;
  @Input() autoWidthDisabled: boolean;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event) {
    this.setStyle();
  }

  ngAfterViewInit() {
    this.setStyle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setStyle();
  }

  setStyle() {
    if (this.autoWidthDisabled) {
      return;
    }
    const DEFAULT_LENGHT = 1;
    const DEFAULT_PADDING = '7px';
    const valueLength = this.elementRef.nativeElement.value.toString().length;
    const ch = `calc(${(valueLength || DEFAULT_LENGHT) + 1.2}ch + ${DEFAULT_PADDING})`;
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', ch);
  }
}
