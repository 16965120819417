<div class="modal-container c-modal" [ngClass]="{ 'fixed-modal': fixed }">
  <div class="c-modal-header">
    <ng-content select="[header]"></ng-content>
  </div>

  <div class="c-modal-body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="c-modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
