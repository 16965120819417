import { BoardLabel, BoardStatus, CustomListColumn, Group, Metrics, ProjectEpicModel, Role, SelectOption, Sprint, Task, TaskStatus, Team, UserShort, WorkItemPriority, WorkItemType } from '..';
import { ShareTargetType } from './share.model';
import { Priority, ProjectType, Status } from '../_utils';
import { PageableModel } from './generic';

export class ProjectCache {
  project: Project;
  static shared = new ProjectCache();

  set(project: Project) {
    this.project = project;

    // Post data to mobile platform
    window['AndroidBridge']?.receiveMessage?.(JSON.stringify(project));
  }

  get() {
    return this.project;
  }
}

export interface Project {
  id: number;
  name: string;
  key: string;
  description: string;
  startDate: string;
  endDate: string;
  owner: UserShort;
  type: ProjectType;
  // leaderId: number;
  // ownerId: number;
  privateFlg: boolean;
  status: Status;
  deleted: boolean;
  jiraUrl: string;
  numTask: number;
  numMember: number;
  leader: ProjectMember;
  avatar: string;
  createdAt: string;
  internalKey: string;
  storylineFlg: boolean;
  sumSubTaskSp: boolean;
  hasChildren: boolean;
  parentId?: number;
  treeStatus?: string;
  parentProject: Project;
  showChildProject?: boolean;
  childrenProject?: Project[];
  linkedJira?: boolean;
  userLoginRole?: Role;
  defaultChangeStatus?: BoardStatus;
  spToHour?: number;
  autoCalculateFlg?: boolean;
  canView?: boolean;
  canEdit?: boolean;
  multiAssigneeFlg?: boolean;
}

export interface ProjectShort {
  id: number;
  name: string;
  key: string;
  startDate?: string;
  endDate?: string;
  type?: ProjectType;
}

export interface ProjectEditRequest {
  id?: number;
  name: string;
  key: string;
  description: string;
  startDate?: string;
  endDate?: string;
  ownerId?: number;
  type: ProjectType;
  status?: Status;
  leaderId: number;
  privateFlg: boolean;
  avatar: string;
  internalKey: string;
  storylineFlg: boolean;
  parentId?: number;
  jiraEditable?: boolean;
  sumSubTaskSp?: boolean;
  defaultChangeStatusId?: number;
  spToHour?: number;
  autoCalculateFlg?: boolean;
  multiAssigneeFlg?: boolean;
}

export interface ProjectTrackingReportResponse {
  id: number;
  name: string;
  key: string;
  jiraUrl: string;
  avatar: string;
}

export interface ProjectSearchParam {
  keyword?: string;
  types?: string[];
  privateFlg?: boolean;
  onlyMyProject?: boolean;
  archivedFlg?: boolean;
  page?: number;
  size?: number;
  sort?: string;
}

export interface ProjectMemberSearchParam {
  projectId: number;
  keyword?: string;
  groupIds?: number[];
  teamIds?: number[];
  roleIds?: number[];
  includeProjectManager?: boolean;
  taskScheduleId?: number;
}

export interface ProjectMember {
  id: number;
  createAt: string;
  role: ProjectRole;
  member: any;
  type: 'Group' | 'Team' | 'User';
  projectManager?: boolean;
}

export interface ProjectMemberUpsert {
  userIds: number[];
  groupIds: number[];
  teamIds: number[];
  projectRoleId: number;
}

export interface ProjectMemberEdit {
  memberId: number;
  roleId: number;
}

export interface ProjectPriority {
  id: number;
  name: string;
  icon: string;
  defaultFlg: boolean;
  deleted: boolean;
  code: Priority;
  displayValue: string;
}

export interface ProjectRole {
  id: number;
  code: string;
  name: string;
  defaultFlg: boolean;
}

export interface ProjectMemberUser {
  fullName: string;
  avatar: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  username: string;
  status: string;
  id: number;
}

export interface ChangeOrderRequest {
  objectId: number;
  preObjectId: number;
  nextObjectId: number;
}

export interface ProjectPlatform {
  id: number;
  name: string;
  description: string;
  platformOrder: number;
  colorCode: string;
  icon: string;
}

export interface ProjectPlatformCreateRequest {
  id?: number;
  name: string;
  description: string;
  platformOrder: number;
  colorCode: string;
  icon: string;
  projectId: number;
}

export interface ProjectPlatformSearchRequest {
  keyword?: string;
}

export interface ProjectMasterData {
  [key: number]: {
    platforms: ProjectPlatform[];
  };
}

export interface ProjectLabel {
    id: number;
    name: string;
    colorCode: string;
    deleted: boolean;
}

export interface ProjectLabelDetail {
  name: string;
  colorCode: string
}

export class SearchProjectLabel extends PageableModel {
  keyword?: string;

  constructor(obj?: SearchProjectLabel) {
    super(obj)
    this.keyword = obj?.keyword;
  }
}


export class ExcludeProject {
  excludeProjectId: number;
  constructor(id: number) {
    this.excludeProjectId = id;
  }
}

export interface BaseCacheFilter {
  includeUnassigned?: SelectOption<boolean>[];
  includeSubtask?: boolean;
  assignee?: ProjectMemberUser[];
  reporter?: ProjectMemberUser[];
  group?: Group[];
  team?: Team[];
  taskType?: WorkItemType[];
  taskKey?: Task[];
  epic?: ProjectEpicModel[];
  priority?: WorkItemPriority[];
  taskStatus?: TaskStatus[];
  platform?: ProjectPlatform[];
  metric?: Metrics[];
  label?: BoardLabel[];
  sprint?: Sprint[];
  fromDate?: string;
  toDate?: string;
  selectedDateFilter?: SelectOption;
  period?: SelectOption[];
}

export interface ActiveSprintCacheFilter extends BaseCacheFilter {
  canOrderTask?: boolean;
  currentTab?: string;
}

export interface ReleaseDetailCacheFilter extends BaseCacheFilter {
  currentList?: string;
}

export interface BacklogCacheFilter extends BaseCacheFilter {
  showClosedTaskFlg?: boolean;
  userIds?: number[];
  taskColumns?: CustomListColumn[];
}

export interface EpicCacheFilter extends BaseCacheFilter {
  sprint?: Sprint[];
}

export interface ProjectCloneRequest {
  id: number;
  name: string;
  key: string;
  membersFlag: boolean;
  tasksFlag: boolean;
}

export interface ImportProjectProgress {
  current: number;
  total: number;
  content: string;
}

export interface ProjectRoles {
  projectId: number;
  roles: Role[];
}

export interface MemberGenerateTask {
  id: number;
  name: string;
  avatar: string;
  description: string;
  status: string;
  type: ShareTargetType;
  email: string;
  orderNum: number;
}
