import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetLinkResponse, List2Res, Pageable, ShareTarget } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { TaskFilterRequest, TaskFilterResponse, TaskFilterShareRequest, TaskFilterShareResponse } from '../_models/task-filter.models';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class TaskFilterService {

  constructor(private http: HttpClient) { }

  getMyFilter(keyword: string, pageable: Pageable) {
    const params = queryString.stringify({keyword, ...pageable});
    return this.http.get<List2Res<TaskFilterResponse>>(`${environment.apiUrl}/task-filter/my-filter/?${params}`);
  }

  getSharedFilter(keyword: string, pageable: Pageable) {
    const params = queryString.stringify({keyword, ...pageable});
    return this.http.get<List2Res<TaskFilterResponse>>(`${environment.apiUrl}/task-filter/shared-filter/?${params}`);
  }

  saveFilter(payload: TaskFilterRequest) {
    return this.http.post<TaskFilterResponse>(`${environment.apiUrl}/task-filter/`, payload);
  }

  getFilterById(id: number) {
    return this.http.get<TaskFilterResponse>(`${environment.apiUrl}/task-filter/${id}`);
  }

  updateFilter(id: number, payload: TaskFilterRequest) {
    return this.http.put<TaskFilterResponse>(`${environment.apiUrl}/task-filter/${id}`, payload);
  }

  deleteFilter(id: number) {
    return this.http.delete<TaskFilterResponse>(`${environment.apiUrl}/task-filter/${id}`);
  }

  sendSharedUser(payload: TaskFilterShareRequest) {
    return this.http.post(`${environment.apiUrl}/task-filter/send-shared-user`, payload);
  }

  createLinkShare(payload: TaskFilterShareRequest) {
    return this.http.post<GetLinkResponse[]>(`${environment.apiUrl}/task-filter/create-link-share`, payload);
  }

  getFilterSharedInfo(filterIds: number[]) {
    const params = queryString.stringify({filterIds});
    return this.http.get<TaskFilterShareResponse[]>(`${environment.apiUrl}/task-filter/share/?${params}`);
  }

  shareFilter(payload: TaskFilterShareRequest) {
    return this.http.post(`${environment.apiUrl}/task-filter/share`, payload);
  }

  updateFilterSharedInfo(payload: TaskFilterShareRequest) {
    return this.http.put(`${environment.apiUrl}/task-filter/share`, payload);
  }

  deleteFilterSharedInfo(body: TaskFilterShareRequest) {
    const deleteShareApi = `${environment.apiUrl}/task-filter/share`;
    return this.http.request('delete', deleteShareApi, { body });
  }

  deleteAllFilterSharedInfo(body: TaskFilterShareRequest) {
    const deleteAllShareApi = `${environment.apiUrl}/task-filter/share/delete-all`;
    return this.http.request('delete', deleteAllShareApi, { body });
  }

  getShareTarget(keyword: string) {
    const params = queryString.stringify({keyword});
    return this.http.get<List2Res<ShareTarget>>(`${environment.apiUrl}/task-filter/sharing-user-group-team/?${params}`);
  }

  getFilterByCode(code: string) {
    const params = queryString.stringify({code});
    return this.http.get<TaskFilterResponse>(`${environment.apiUrl}/task-filter/get-by-code/?${params}`);
  }
}
