<div dropdown
  container="body"
  [placement]="placement"
  [ngClass]="customClass"
  (onShown)="onShowDropdown()"
  (onHidden)="onHideDropdown()"
  (isOpenChange)="onToggleDropdown($event)"
>
  <button
    id="button-alignment"
    dropdownToggle
    type="button"
    class="default btn btn-icon btn-dropdown"
    [ngClass]="{'w-unset': !isSetWidth , 'bg-dropdown' : !transparent }"
    aria-controls="dropdown-alignment"
    stopEvent
  >
    <span class="material-icons-outlined"> {{ icon }} </span>
  </button>
  <ul
    id="dropdown-alignment"
    *dropdownMenu
    class="dropdown-menu"
    [ngClass]="dropdownClass"
    role="menu"
    aria-labelledby="button-alignment"
  >
    <li role="menuitem">
      <ng-content></ng-content>
    </li>
  </ul>
</div>
