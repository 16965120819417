import { SelectOption } from '../../_models';
import { UserGenderValue, UserMaritalValue, UserStatusKey } from '../_enums';

export const UserMaritalStatus = [
  {
    label: 'Married',
    value: UserMaritalValue.MARRIED
  },
  {
    label: 'Single',
    value: UserMaritalValue.SINGLE
  }
];

export const UserGenders = [
  {
    label: 'Male',
    value: UserGenderValue.MALE
  },
  {
    label: 'Female',
    value: UserGenderValue.FEMALE
  }
];

export const UserStatus = [
  {
    name: 'Active',
    value: UserStatusKey.ACTIVE,
  },
  {
    name: 'Inactive',
    value: UserStatusKey.INACTIVE,
  },
  {
    name: 'All',
    value: null,
  }
];

export const UnassignedId = 0;
export const HOTLINE_NUMBER = '0896886969';
export const CURRENT_USER_DOMAIN = 'expercCurrentUserDomain';

export const DefaultUnassigned = { name: 'Yes', value: true };

export const UnassigneeOptions = [
  DefaultUnassigned,
  { name: 'No', value: false }
];

export const getUnassignedValue = (options: SelectOption[]): boolean => {
  return options?.length ? options.some((e) => e.value) : undefined;
};
