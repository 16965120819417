import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CustomListColumn, IconType } from 'src/app/shared';
import { ColumnUpdater } from './column-updater';

@Component({
  selector: 'x-columns-dropdown',
  templateUrl: './columns-dropdown.component.html',
  styleUrls: ['./columns-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ColumnsDropdownComponent {
  @Input() dropdownColumns: CustomListColumn[] = [];
  @Output() change = new EventEmitter<CustomListColumn[]>();
  @Output() updateConfig = new EventEmitter<Record<string, boolean>>();

  keyword: string;
  type = IconType;

  onSearchColumn(keyword: string) {
    this.keyword = keyword;
  }

  updateColumn(column: CustomListColumn) {
    const isSelect = column.selected !== false;
    column.selected = !isSelect;
    this.change.emit(this.dropdownColumns);
    this.updateConfig.emit(ColumnUpdater.getColumnConfig(this.dropdownColumns));
  }
}
