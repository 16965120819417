import { Field, Task, TaskTypeEnum } from "src/app/shared";
import { selectEpicPermission, selectStorylinePermission, selectTaskPermission, selectTaskTemplatePermission } from "./permission.selectors";
import { AppInjector } from "src/app/app.module";
import { AuthService } from "src/app/authentication/_services/auth.service";
import { TaskField, TaskFieldKey } from "../dynamic-field";
import { TaskPermissionChecker } from "../../tasks/_services/task-permission.service";
import { isTaskBelongedToUser } from "../task/task.utils";

export const getTaskPermissionSelector = (task: Task, projectId?: number) => {
  const resolvedProjectId  = projectId ?? task.project?.id ?? task?.projectId;
  const permissionSelectorMap = {
    [TaskTypeEnum.Epic.toUpperCase()]: selectEpicPermission(resolvedProjectId),
    [TaskTypeEnum.Storyline.toUpperCase()]: selectStorylinePermission(resolvedProjectId),
  }

  return permissionSelectorMap[task.type.code] ??
    (task?.taskTemplate ? selectTaskTemplatePermission(resolvedProjectId) : null) ??
    selectTaskPermission(resolvedProjectId);
}

/**
 * Update the task fields's permission based the target task
 * @param fields
 * @param task
 * @param permissionChecker
 * @returns taskFields's permission after updated
 */
export const updateTaskFieldsPermission = (fields: Field[], task: Task, permissionChecker: TaskPermissionChecker) => {
  const _auth = AppInjector.get(AuthService);
  const isSuperAdmin = _auth.getLoggedUserRole()?.isSuperAdmin;
  const taskFields: TaskField = {};

  if (isSuperAdmin) {
    Object.values(TaskFieldKey).forEach(key => {
      taskFields[key] = { visible: true, disabled: false }
    });
    return taskFields;
  }

  if (!fields || !permissionChecker) {
    return null;
  }

  const canEditAfterDueDate = permissionChecker.canEditAfterDueDate(task?.dueDate);

  fields.forEach(field => {
    const isDisabled =
      !canEditAfterDueDate ||
      (!isTaskBelongedToUser(task, _auth.loggedUser) &&
        !permissionChecker.canEditAnother) ||
      (field?.disabled ?? false);

      taskFields[field.hash as TaskFieldKey] = {
      visible: field.isVisible,
      disabled: isDisabled
    }
  });

  return taskFields;
}
