import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { GeneralConfirmComponent } from "src/@xcorp/components/general-confirm/general-confirm.component";

@Injectable({
  providedIn: 'root'
})
export class PendingChangesService {
  constructor(private _dialog: MatDialog) { }

  showConfirmDialog(): Observable<boolean> {
    const ref = this._dialog.open(GeneralConfirmComponent, {
      data: {
        title: 'Warning!',
        message: 'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
        saveBtnName: 'Leave without Save',
        cancelBtnName: 'Cancel',
      },
      disableClose: true
    });

    return ref.afterClosed();
  }
}
