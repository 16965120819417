<div class="input-group search-input-wrapper">
  <input
    #searchInput
    [placeholder]="placeholder | translate"
    [(ngModel)]="text"
    class="form-control"
    (keyup.enter)="onSearch()"
    (input)="onInput($event)"
  />
  <div
    class="input-group-prepend"
    (click)="onSearch()"
    [tooltip]="'Search' | translate"
    placement="bottom"
  >
    <span class="input-group-text search-icon"
      ><i class="fa fa-search" aria-hidden="true"></i
    ></span>
  </div>
</div>
