<div class="custom-invalid-feedback" *ngIf="form">
  <span
    *ngIf="(form.hasError('required', [controlName]) || form.hasError('whitespace', [controlName])) && form.get(controlName).touched">
    <app-i18n [key]="'This field is required'"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('whitespaceNoRequired', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'Not allow only whitespace'"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('leadingSpace', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'Not allow leading space'"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('trailingSpace', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'Not allow trailing space'"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('maxlength', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="maxLengthMessage | translate"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('minlength', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'Too short'"></app-i18n>!
  </span>
  <span *ngIf="customError && form.hasError(customError.error, [controlName])">
    {{customError.message | translate}}
  </span>
  <span *ngIf="form.hasError('invalid', [controlName]) && form.get(controlName).touched">{{patternMessage | translate}}</span>
  <span *ngIf="form.hasError('max', [controlName]) && form.get(controlName).touched"><app-i18n [key]="'Invalid value'"></app-i18n>!</span>
  <span *ngIf="form.hasError('min', [controlName]) && form.get(controlName).touched">{{minMessage | translate}}</span>
  <span *ngIf="form.hasError('email', [controlName]) && form.get(controlName).touched"><app-i18n [key]="'Email is invalid'"></app-i18n>!</span>
  <span
    *ngIf="!(form.hasError('required', [controlName]) || form.hasError('whitespace', [controlName])) && form.hasError('pattern', [controlName]) && (form.get(controlName).touched || form.get(controlName).dirty)">
    {{patternMessage | translate}}
  </span>
  <span
    *ngIf="form.hasError('mustMatch') && form.get('confirmPassword')?.dirty && form.get('password')?.touched && (controlName === 'password' || controlName === 'confirmPassword')">
    <app-i18n [key]="'passwordNotMatch'"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('invalidLogTime', [controlName]) && form.get(controlName).touched"><app-i18n
      [key]="'Invalid time log format'"></app-i18n>!</span>
  <!-- Username errors -->
  <span *ngIf="form.hasError('usernameInvalidChars', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'usernameInvalidCharsMessage' | translate"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('usernameNoAlphabet', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'usernameNoAlphabetMessage' | translate"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('usernameTooShort', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'usernameTooShortMessage' | translate"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('usernameTooLong', [controlName]) && form.get(controlName).touched">
    <app-i18n [key]="'usernameTooLongMessage' | translate"></app-i18n>!
  </span>
  <span *ngIf="form.hasError('usernameExisted', [controlName])">
    <app-i18n [key]="'usernameExistedMessage' | translate"></app-i18n>!
  </span>
  <span *ngFor="let err of errors">{{err}}</span>
</div>
