import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  BsDatepickerConfig,
  BsDaterangepickerContainerComponent,
  BsDaterangepickerDirective
} from 'ngx-bootstrap/datepicker';
import { DATE_FORMAT, DATE_UI_FORMAT, FilterItem, SelectOption } from '../..';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { filter } from 'rxjs';
export const CustomLabel = 'Custom';

@Component({
  selector: 'app-period-filter',
  templateUrl: './period-filter.component.html',
  styleUrls: ['./period-filter.component.scss']
})
export class PeriodFilterComponent implements OnInit {
  @ViewChild(BsDaterangepickerDirective, { static: false })
  dateRangePicker: BsDaterangepickerDirective;

  @Input() filterItem: FilterItem<any>;
  @Output() filterItemChange = new EventEmitter();
  @Input() startKey = 'startDate';
  @Input() endKey = 'endDate';
  @Input() limitEndDate: number = 0;
  @Input() showCustomDate: boolean = false;

  dateRange: Date[] = [];
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    rangeInputFormat: DATE_UI_FORMAT,
    customTodayClass: 'bs-datepicker-today'
  };

  constructor() {}

  ngOnInit(): void {}

  onSelectOption(event: SelectOption, datePicker: HTMLInputElement) {
    if (event.name === CustomLabel) {
      datePicker.click();
    } else {
      this.filterItem.selecteds = [event];
      this.filterItemChange.emit(this.filterItem);
    }
  }

  onChangeDate(event: Date[]) {
    if (isEmpty(event)) {
      return;
    }

    const [startDate, endDate] = event;
    const selectedLabel = this.showCustomDate
      ? moment(startDate).format(DATE_UI_FORMAT) +
        ' - ' +
        moment(endDate).format(DATE_UI_FORMAT)
      : CustomLabel;

    const selected = {
      name: selectedLabel,
      value: {
        [this.startKey]: moment(startDate).format(DATE_FORMAT),
        [this.endKey]: moment(endDate).format(DATE_FORMAT)
      }
    };
    this.filterItem.selecteds = [selected];
    this.filterItemChange.emit(this.filterItem);
  }

  onDatepickerShown(): void {
    if (!this.limitEndDate) {
      return;
    }

    const datePickerInstance: BsDaterangepickerContainerComponent =
      this.dateRangePicker['_datepicker'].instance;
    datePickerInstance.valueChange
      .pipe(filter((value) => value && value[0] && !value[1]))
      .subscribe((value: Date[]) => {
        const [startDate] = value;
        const startMoment = new Date(startDate);
        const maxMoment = new Date(startDate);
        // Set max date to limitEndDate days
        maxMoment.setDate(maxMoment.getDate() + this.limitEndDate);

        datePickerInstance._effects.setMinDate(startMoment);
        datePickerInstance._effects.setMaxDate(maxMoment);
      });
  }
}
