import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, debounceTime, takeUntil, startWith } from 'rxjs';
import { TaskFilterService } from 'src/app/site-management/tasks/task-list/_services/task-filter.service';
import { ProjectsService } from 'src/app/site-management/projects/_services';
import {
  MemberGenerateTask,
  Pageable,
  SharedItem,
  SharePermissions,
  ShareTarget,
  ShareTargetType,
  SharingPayload
} from '../../_models';

@Component({
  selector: 'x-allocate',
  templateUrl: './allocate.component.html',
  styleUrls: ['./allocate.component.scss']
})
export class AllocateComponent implements OnInit, OnDestroy {
  @Input() includePermission = true;
  @Input() placeholder = 'Add members, teams, groups';
  @Input() sharedTemplateRef: TemplateRef<any>;
  @Input() allocatedList: SharedItem[] = [];
  @Input() projectId?: number;
  @Input() searchParams?: any = {};
  @Output() add = new EventEmitter<SharingPayload>();
  @Output() update = new EventEmitter<SharedItem[]>();

  form: UntypedFormGroup;
  targets: ShareTarget[] = [];
  permissions = SharePermissions;
  targetType = ShareTargetType;
  keyword: string = '';

  inputTypeahead$ = new Subject<string>();
  destroyed$ = new Subject<void>();

  // TODO: Need to request BE for updating endpoint for getting share-target: {users, teams, groups}
  constructor(
    private _share: TaskFilterService,
    private _projectMember: ProjectsService,
    private _fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.observeEvents();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  createForm() {
    this.form = this._fb.group({
      target: [],
      permission: [],
    });

    if (this.includePermission) {
      this.form.get('permission').addValidators(Validators.required);
      this.form.get('permission').setValue(this.permissions[0]);
    }
  }

  observeEvents() {
    this.inputTypeahead$.pipe(startWith(''), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(value => {
        this.keyword = value;
        this.searchShareTargets();
      });
  }
  
  searchShareTargets() {
    if (this.projectId) {
      this.searchProjectTargets();
    } else {
      this.searchCompanyTargets();
    }
  }

  searchProjectTargets() {
    this._projectMember.getGenerateTaskMember(this.projectId, this.keyword, new Pageable())
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: response => {
          this.targets = this.mapMembersToShareTargets(response.content);
        },
        error: () => {
          this.targets = [];
        }
      })
  }
  
  searchCompanyTargets() {
    this._share.getShareTarget(this.keyword)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: response => {
          this.targets = response.content;
        },
        error: () => {
          this.targets = [];
        }
      });
  }

  onAdd() {
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const rawValue = this.form.getRawValue();
    const payload: SharingPayload = {
      targets: rawValue.target,
      permission: rawValue?.permission,
    };

    this.add.emit(payload);
    this.form.get('target').reset();
    this.addToSharedList(payload);
  }

  removeItem(item: ShareTarget) {
    const targetValue = this.form.get('target').value;
    const index = targetValue.findIndex(el => el?.objectId === item?.objectId);
    if (index !== -1) {
      targetValue.splice(index, 1);
    }
    this.form.get('target').setValue(targetValue);
  }

  addToSharedList(payload: SharingPayload) {
    payload.targets.forEach(target => {
      /** Verify exist */
      const existedItem = this.allocatedList.find(item => item.target.objectId === target.objectId);

      if (!existedItem) {
        this.allocatedList.push({ target, permission: payload.permission });
      }
    });

    this.update.emit(this.allocatedList);
  }

  updateShareTarget(shareItem: SharedItem, isDelete = false) {
    if (isDelete) {
      const shareIdx = this.allocatedList.findIndex(item => item.target.objectId === shareItem.target.objectId);
      this.allocatedList.splice(shareIdx, 1);
    }

    this.update.emit(this.allocatedList);
  }

  mapMembersToShareTargets(members: MemberGenerateTask[]) {
    return members.map<ShareTarget>(member => {
      const { id, avatar, name, email, type } = member;

      return {
        objectId: +id,
        objectName: name,
        objectAvatar: avatar,
        objectEmail: email,
        objectType: type,
      }
    })
  }
}
