import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { sampleTime, takeUntil } from 'rxjs/operators';

@Directive({ selector: '[auto-sync-scroll]' })
export class AutoSyncScrollDirective implements OnDestroy {
  @Input() enable: boolean = true;
  @Input() ref: HTMLElement;
  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.enable) {
      fromEvent(this.ref, 'scroll')
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res) {
            this.elementRef.nativeElement.scrollLeft = res.target.scrollLeft;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
