import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PendingChangesService } from '../_services/pending-changes.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard  {
  constructor(private _pendingChanges: PendingChangesService) { }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate()
      ? true
      : this._pendingChanges.showConfirmDialog();
  }
}
