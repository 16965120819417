<div class="custom-table relative">
  <div
    class="ml-auto d-flex justify-content-end mx-1 mt-1 mb-3"
    *ngIf="canSearchRow()"
  >
    <app-search-input (search)="onSearchText($event)"></app-search-input>
  </div>

  <div class="column-settings-container" 
    [ngClass]="{
      'empty-column': columnUpdater.isEmpty
    }"
    [attr.empty-text]="columnUpdater.emptyText | translate"
    *ngIf="columnUpdater"
  >
    <x-columns-dropdown
      class="pr-3"
      [(dropdownColumns)]="columnUpdater.columnSettings"
      (updateConfig)="onColumnChange($event)"
      [style.top.px]="10"
      [style.right.px]="0"

    ></x-columns-dropdown>
  </div>

  <div class="table-header" *ngIf="columnUpdater && !columnUpdater.isEmpty">
    <div class="table-row">
      <ng-container *ngFor="let col of field?.columns">
        <div
          class="table-cell"
          *ngIf="columnUpdater?.config?.[col.name] !== false"
          [ngStyle]="{ order: col.rowIndex || 1 }"
        >
          <span
            class="col-name d-block text-ellipsis"
            *ngIf="col.name as colName"
            [title]="colName"
            >{{ colName }}</span
          >
        </div>
      </ng-container>
      <div
        *ngIf="columnUpdater?.config?.[actionColumnKey] !== false"
        class="table-cell action-cell"
        [ngStyle]="{ order: field.columns?.length + 1 }"
      ></div>
    </div>
  </div>

  <div class="table-body" *ngIf="columnUpdater && !columnUpdater.isEmpty">
    <ng-container *ngFor="let row of rows">
      <div class="table-row" *ngIf="!row?.deleteFlag">
        <ng-container *ngFor="let col of field.columns">
          <div
            class="table-cell text-ellipsis"
            [ngStyle]="{ order: col.rowIndex || 1 }"
            *ngIf="columnUpdater?.config?.[col.name] !== false"
          >
            <div class="w-100">
              <app-d-field-cell-table
                [row]="row"
                [column]="col"
              ></app-d-field-cell-table>
            </div>
          </div>
        </ng-container>
        <div
          class="table-cell action-cell"
          [ngStyle]="{ order: field.columns?.length + 1 }"
          *ngIf="columnUpdater?.config?.[actionColumnKey] !== false"
        >
          <div
            dropdown
            container="body"
            placement="bottom right"
            *ngIf="!row?.isUpdatingFlag"
          >
            <button
              id="button-alignment"
              dropdownToggle
              type="button"
              class="btn btn-action-toggle"
              aria-controls="dropdown-alignment"
            ></button>
            <ul
              id="dropdown-alignment"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-alignment"
            >
              <li role="menuitem">
                <a class="dropdown-item" (click)="onEdit(row)"
                  ><app-i18n [key]="'Edit'"></app-i18n
                ></a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" (click)="onDelete(row)"
                  ><app-i18n [key]="'Delete'"></app-i18n
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="mobile-wrapper">
      <ngx-pagesize
        [pageInfo]="pageInfo"
        [theme]="'material'"
        (pageChange)="onPage($event)"
        (pageSizeChange)="onPageSizeChange($event)"
      ></ngx-pagesize>
    </div>
  </div>
</div>

<div class="mt-2">
  <button
    class="btn btn-flex btn-link"
    (click)="showUpsertModal()"
    [disabled]="isLoadingRow"
  >
    + Add new record
  </button>
</div>
