<!-- <div class="dropdown-control" dropdown #dropdown="bs-dropdown" container="body" [insideClick]="true"
  placement="bottom right" (click)="onOpenSearch()">

  <div class="pointer" dropdownToggle>
    <ng-container *ngIf="config?.selectedTemplate">
      <ng-container *ngTemplateOutlet="config.selectedTemplate; context: {selecteds : config.selecteds}"></ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="canEdit">
  <div class="dropdown-menu custom-search-dropdown-menu dropdown-menu-right" *dropdownMenu>
    <div class="header" *ngIf="config.title || config.searchable">
      <div class="title" *ngIf="config.title">{{config.title}}</div>

      <app-search-input (keywordChange)="config.searchTypeahead$.next($event)"
        *ngIf="config.searchable"></app-search-input>
    </div>
    <div class="option-list">
      <ng-container *ngFor="let item of config.items">
        <div class="custom-option" [ngClass]="{'selected': isSelected(item)}"
          (click)="toggleItem(item, dropdown)">
          <ng-container *ngIf="config.optionTemplate">
            <ng-container *ngTemplateOutlet="config.optionTemplate; context: {item : item}"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div class="text-center" *ngIf="!config.loading && config.items.length===0"><app-i18n
          [key]="'No options'"></app-i18n></div>
      </div>
    <div class="footer" *ngIf="config.footerTemplate" (click)="dropdown.isOpen = false">
      <ng-container *ngTemplateOutlet="config.footerTemplate"></ng-container>
    </div>
  </div>
  </ng-container>
</div> -->

<a class="btn-link btn-search-overlay"
  nz-button
  #dropdown
  nz-dropdown
  [nzClickHide]="!config.multiple"
  [nzDropdownMenu]="nzDropdown"
  [nzDisabled]="!canEdit"
  [nzTrigger]="'click'"
  (nzVisibleChange)="onOpenSearch($event)"
  stopEvent>
  <ng-container *ngIf="config?.selectedTemplate">
    <ng-container *ngTemplateOutlet="config.selectedTemplate; context: {selecteds : config.selecteds}"></ng-container>
  </ng-container>
</a>

<nz-dropdown-menu #nzDropdown="nzDropdownMenu">
  <div nz-menu
       class="custom-search-dropdown-menu search-overlay-custom-dropdown-menu">
       <div class="header" *ngIf="config.title || config.searchable">
        <div class="title" *ngIf="config.title">{{config.title | translate }}</div>

        <app-search-input
          [text]="searchText"
          (keywordChange)="onSearchText($event)"
          *ngIf="config.searchable"
        ></app-search-input>
      </div>
      <div class="option-list">
        <ng-container *ngFor="let item of config.items">
          <div class="custom-option" nz-menu-item [ngClass]="{'selected': isSelected(item)}"
            (click)="toggleItem(item, dropdown)">
            <ng-container *ngIf="config.optionTemplate">
              <ng-container *ngTemplateOutlet="config.optionTemplate; context: {item : item}"></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <div class="text-center py-2" *ngIf="!config.loading && config.items.length===0"><app-i18n
            [key]="'No options'"></app-i18n></div>
        </div>
      <div class="footer py-0" *ngIf="config.footerTemplate" nz-menu-item>
        <ng-container *ngTemplateOutlet="config.footerTemplate"></ng-container>
      </div>
  </div>
</nz-dropdown-menu>
