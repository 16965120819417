<x-modal-container>
  <ng-container header>
    <x-i18n [key]="title" [params]="titleParams"></x-i18n>
  </ng-container>
  <ng-container body>
    <div class="md-modal">
      <x-i18n *ngIf="message" [key]="message" [params]="params"></x-i18n>
      <ng-template
        *ngIf="templateRef"
        [ngTemplateOutlet]="templateRef"
      ></ng-template>
    </div>
  </ng-container>

  <ng-container footer>
    <div *ngIf="canAction">
      <button type="button" class="btn btn-white" (click)="onNoClick()">
        <x-i18n [key]="cancelBtnName"></x-i18n>
      </button>
      <button type="button" class="btn btn-primary ml-2" (click)="onYesClick()">
        <x-i18n [key]="saveBtnName"></x-i18n>
      </button>
    </div>
  </ng-container>
</x-modal-container>
