<div class="navbar-wrapper web-navbar container-fluid" *ngIf="isWebLayout">
  <div class="row row-content">
    <div class="col-12 d-flex align-self-center">
      <div class="d-flex align-items-center w-100">
        <a [routerLink]="routerObject.home.fullPath">
          <img
            class="company-logo"
            src="../../../../assets/img/logo.svg"
            alt=""
          />
        </a>

        <ng-container *ngTemplateOutlet="companyTemplate"></ng-container>

        <div class="d-flex-center flex-fill">
          <ng-container *ngIf="templateRef">
            <ng-template
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{}"
            >
            </ng-template>
          </ng-container>

          <div class="ml-2">
            <app-trial-badge></app-trial-badge>
          </div>
        </div>

        <div class="navbar-menu ml-auto">
          <ng-container *ngTemplateOutlet="action"></ng-container>

          <div
            class="user-account-dropdown"
            dropdown
            #dropdown="bs-dropdown"
            container="body"
            placement="bottom right"
          >
            <div class="pointer" dropdownToggle>
              <app-avatar
                [size]="'large'"
                [url]="user.avatar"
                [name]="user.fullName"
                [hideTooltip]="true"
              ></app-avatar>
            </div>
            <div
              class="dropdown-menu dropdown-menu-right user-account-dropdown-menu"
              *dropdownMenu
            >
              <div class="header main-acc">
                <app-avatar
                  [url]="user.avatarLarge"
                  [name]="user.fullName"
                ></app-avatar>
                <div class="fw-6 mt-2">{{ user.fullName }}</div>
                <div>{{ user.email }}</div>
                <div class="text-primary">{{ user.company.domain }}</div>
                <a
                  class="btn btn-outline-secondary mt-3"
                  [routerLink]="'/organization/users/my-profile'"
                  ><app-i18n [key]="'My Profile'"></app-i18n
                ></a>
                <a
                  class="btn btn-outline-secondary mt-3"
                  (click)="onChangePassword()"
                  ><app-i18n [key]="'Change Password'"></app-i18n
                ></a>
              </div>

              <div class="acc-list company-list">
                <div class="company-wrapper">
                  <app-switch-company></app-switch-company>
                </div>
              </div>

              <div class="acc-list">
                <div
                  class="acc-wrapper add_acc justify-content-center"
                  (click)="onClickAbout()"
                  [routerLink]="['/w']"
                >
                  <app-avatar
                    [size]="'medium'"
                    [url]="'../../../../assets/icons/ic_black_xcorp.svg'"
                    [hideTooltip]="true"
                  ></app-avatar>
                  <div class="right-block">
                    <span class="fw-6">xCorp® Homepage</span>
                  </div>
                </div>
              </div>
              <div class="footer">
                <button class="btn btn-outline-secondary" (click)="signOut()">
                  <app-i18n [key]="'Sign out'"></app-i18n>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="navbar-wrapper mobile-navbar container-fluid" *ngIf="!isWebLayout">
  <div class="row">
    <div class="col-12">
      <div class="navbar-content">
        <app-privacy-setting></app-privacy-setting>
        <ng-container *ngIf="templateRef">
          <span class="btn-arrow-right"></span>
          <ng-template
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{}"
          >
          </ng-template>
        </ng-container>

        <div class="icon-menu">
          <ng-container *ngTemplateOutlet="action"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #action>
  <div class="">
    <button
      class="btn p-0 btn-search border-0"
      (click)="onSearch()"
      [tooltip]="('Press hotkey' | translate) + ': Ctrl + K'"
    >
      <mat-icon [svgIcon]="'navbar_search'"></mat-icon>
    </button>
  </div>
  <div class="switch-language-wrapper">
    <app-switch-language
      [style]="'icon'"
      [placement]="'bottom'"
    ></app-switch-language>
  </div>
  <div class="notification-system">
    <app-notification-system></app-notification-system>
  </div>
  <div class="support-contact">
    <x-support-contact></x-support-contact>
  </div>
</ng-template>

<ng-template #companyTemplate>
  <div
    class="breadcumb switch-company"
    dropdown
    #dropdown="bs-dropdown"
    container="body"
    [insideClick]="true"
    placement="bottom left"
  >
    <div class="breadcumb-item" dropdownToggle>
      <span class="btn-arrow-right"></span>
      <span>{{ companyName }}</span>

      <div
        class="dropdown-menu custom-search-dropdown-menu navbar-company-dropdown"
        *dropdownMenu
      >
        <div class="option-list">
          <div
            class="custom-option"
            [ngClass]="{ selected: option.company.name === companyName }"
            *ngFor="let option of companyList"
            (click)="dropdown.isOpen = false; changeCompany($event, option)"
          >
            <div class="option-icon">
              <div *ngIf="!option.company?.logo" class="default-logo">
                <span>{{ option.company.name | getShortName }}</span>
              </div>
              <img
                *ngIf="option.company?.logo"
                [src]="option.company.logo"
                class="default-logo"
              />
            </div>
            <a
              [href]="getCompanyLink | function : option.company"
              (click)="onLinkClick($event, option, loggedUser.company.id)"
              class="option-label"
            >
              {{ option?.company?.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
