import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibsModule } from 'src/app/libs/libs.module';
import { SearchInputComponent } from './search-input.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SearchInputComponent,
  ],
  imports: [
    CommonModule,
    LibsModule,
    FormsModule,
  ],
  exports: [
    SearchInputComponent,
  ]
})
export class SearchInputModule { }
