import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DATE_FORMAT_MONTH_NAME_DAY } from '../../../app/shared';
import { XControlAccessor } from 'src/@xcorp/base/control-accessor';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'x-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ]
})
export class DateComponent extends XControlAccessor {
  @Input() dueDateView = false;
  @Input() dateFormat = DATE_FORMAT_MONTH_NAME_DAY;
  @Input() min: Date;
  @Input() max: Date;
  @Input() isInputBlock = false;
  @Input() dateFilter: (date: Date)=> boolean;

  control: FormControl = new FormControl(null, Validators.required);

  constructor() {
    super();
  }

  writeValue(value: any): void {
    this.control.setValue(value, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  onDateChanged(e: MatDatepickerInputEvent<any>) {
    this.onChange(e.value);
    this.onTouched();
    this.valueChange.emit(e.value);
  }
}
