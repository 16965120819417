import { Injectable } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Injectable({
  providedIn: 'root',
})
export class ButtonDropdownService {
  private currentDropdown: BsDropdownDirective | null = null;

  register(dropdown: BsDropdownDirective) {
    if (this.currentDropdown && this.currentDropdown !== dropdown) {
      this.currentDropdown.hide();
    }
    this.currentDropdown = dropdown;
  }

  unregister(dropdown: BsDropdownDirective) {
    if (this.currentDropdown === dropdown) {
      this.currentDropdown = null;
    }
  }
}