<x-dropdown 
  class="columns-dropdown-wrapper" 
  [icon]="'add-column'" 
  [iconType]="type.LOCAL" 
  [tooltip]="'Custom Column'"
>
  <div class="header mt-2">
    <x-search-input (keywordChange)="onSearchColumn($event)"></x-search-input>
  </div>
  <div class="option-list">
    <ng-container *ngFor="let column of dropdownColumns">
      <div
        class="custom-option relative"
        [ngClass]="{ hide: !(keyword | isVisibleColumn : column) }"
        (click)="updateColumn(column)"
      >
        <span class="custom-checkbox-input" [ngClass]="{ checked: column?.selected !== false }"></span>
        <span class="checkbox-label ml-2">
          <x-i18n [key]="column.name"></x-i18n>
        </span>
      </div>
    </ng-container>
  </div>
</x-dropdown>
