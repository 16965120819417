import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from 'src/app/core';
import {
  AccountCompanyResponse,
  CompanyAuthResponse,
  routerObject,
  User
} from 'src/app/shared';
import { SiteManagementState } from '../../_store/site-management';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NavbarService } from './_services/navbar.service';
import {
  AuthActions,
  AuthState,
  selectAccountCompanies
} from 'src/app/core/store/auth';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { MultiCompanyService } from '../../company/_services/multi-company.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppActions } from 'src/app/core/store/app';
import { LOGOUT, REDIRECT } from 'src/app/core/constants/message';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  @Input() isWebLayout = false;
  @Input() user: User;
  @Output() openSearch = new EventEmitter<any>();

  companyName = '';
  loggedUser!: User;
  companyList: AccountCompanyResponse[] = [];
  templateRef: TemplateRef<any>;
  routerObject = routerObject;

  constructor(
    public router: Router,
    private authStore: Store<AuthState>,
    private navbarService: NavbarService,
    private translateService: TranslateService,
    private authService: AuthService,
    private multiCompanyService: MultiCompanyService,
    private siteManagementStore: Store<SiteManagementState>,
    private modal: BsModalService
  ) {
    this.navbarService.templateRef$.subscribe((data) => {
      this.templateRef = data;
    });
    this.loggedUser = this.authService.getLoggedUser();
    this.companyName = this.loggedUser.company.name;
    this.siteManagementStore
      .select(selectAccountCompanies)
      .subscribe((companies) => {
        this.companyList = companies;
      });
  }

  ngOnInit(): void {}

  onClickLogo() {
    // if (window.location.pathname === routerObject.leaveDashboard.fullPath) {
    //   this.router.navigate([routerObject.landingPage.fullPath]);
    // } else {
    //   this.router.navigate(['/']);
    // }
    const redirectUri = `${environment.clientUrl}/${this.translateService.currentLang}${routerObject.home.fullPath}`;
    window.open(redirectUri, '_self');
  }

  signOut() {
    this.authStore.dispatch(
      AppActions.loading({ visible: true, text: LOGOUT, forever: true })
    );
    this.authStore.dispatch(AuthActions.signOut());
  }

  getName(fullName: string) {
    return UtilService.getAbbreviationName(fullName);
  }

  onChangePassword() {
    this.modal.show(ChangePasswordComponent);
  }

  onSearch() {
    this.openSearch.emit();
  }

  changeCompany(event: Event, target: AccountCompanyResponse) {
    const currentCompanyId = this.loggedUser.company.id;
    if (currentCompanyId === target.company.id) {
      return;
    }

    event.stopPropagation();
    this.multiCompanyService.signInCompany(target, true);
  }

  onClickAbout() {
    this.authStore.dispatch(
      AppActions.loading({ visible: true, text: REDIRECT, forever: true })
    );
  }

  getCompanyLink(company: CompanyAuthResponse) {
    return 'https://' + company?.domain;
  }

  onLinkClick(
    event: MouseEvent,
    target: AccountCompanyResponse,
    currentCompanyId: number
  ): void {
    event.stopPropagation();

    if (event.ctrlKey || event.metaKey) {
      return;
    }

    if (currentCompanyId !== target.company.id) {
      this.changeCompany(event, target);
    }

    event.preventDefault();
  }
}
