import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { LogTimeResponse } from 'src/app/shared/_models/time-log.model';

export interface LogWorkChange {
  totalTime: number;
  detail: LogTimeResponse[];
}

@Injectable({
  providedIn: 'root'
})
export class TaskDetailDataService {
  currentWidth$: BehaviorSubject<number> = new BehaviorSubject(null);
  logWorkChange$: Subject<LogWorkChange> = new Subject();
}
